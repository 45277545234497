import React from 'react'

export default function AboutUs() {
  return (
   <section data-aos="zoom-in-down" className='mt-10'>

<div className="my-4 py-4 flex flex-col justify-center items-center">
                        <h2 className="my-2 text-center text-black text-3xl text-blue-900 uppercase font-bold">About Us</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-black lg:max-w-[30%] text-sm lg:text-sm font-normal text-gray-600">
                            
                        At our core, we specialize in crafting digital experiences that simplify and 
enhance life's journey. Through our human-centered approach, we develop 
enterprise software that transforms dreams into tangible reality.

                        </h2>
                    </div>


                    <div className="pb-12 lg:max-w-[70%] ml-auto mr-auto" data-aos="fade-down" data-aos-delay="600">

                    <dl class="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-black text-lg font-[400] text-center">with Satisfied Clients</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-black font-[800] text-center text-[200]">4+ Countries</dd>
        </div>
        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-black text-lg font-[400] text-center">Projected Revenue</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-black font-[800] text-center text-[200]">Usd 1m</dd>
        </div>
        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-black text-lg font-[400] text-center">Anticipated Job Creation</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-black font-[800] text-center text-[200]">2.000 +</dd>
        </div>
        <div class="flex flex-col-reverse">
          <dt class="text-base leading-7 text-black text-lg font-[400] text-center">Desired Projects</dt>
          <dd class="text-2xl font-bold leading-9 tracking-tight text-black font-[800] text-center text-[200]">1000+</dd>
        </div>
      </dl>
</div>

<div className='py-12 grid lg:grid-cols-3 min-h-[75vh] lg:max-w-[70%] ml-auto mr-auto text-white'>

<div className='col-span-1 mt-4 bg-yellow-500 flex-1 px-10 py-10 flex flex-col justify-between align-center'>
<span className='font-[800] text-xl'>
Highlighted Perspectives
    </span>

    <a href="#">Is Direct Neural Interface the Future of Digital Reality?</a>
    <a href="#">Understanding the Significance of the Metaverse</a>
    <a href="#">Decoding Digital Transformation: The Trend of 2022</a>
    <a href="#">Unlocking Potential: 5 Ways NFTs Can Propel Small Businesses</a>

</div>

<div className='col-span-2 ml-0 grid grid-cols-1 lg:grid-cols-2 justify-center items-start'>

<div className="col-span-1 ml-2 mt-4 filter_custom h-[20rem] flex justify-start items-end rounded overflow-hidden shadow-lg" style={{backgroundImage: "url('https://source.unsplash.com/random/?Cryptocurrency/')",backgroundSize:'cover'}}>
<a href="#">
<div className="font-bold text-xl mb-2 px-5 py-5">The Coldest Sunset</div>
</a>
</div>
<div className="col-span-1 ml-2 mt-4 filter_custom h-[20rem] flex justify-start items-end rounded overflow-hidden shadow-lg" style={{backgroundImage: "url('https://source.unsplash.com/random/?Digital/')",backgroundSize:'cover'}}>
<a href="#">
<div className="font-bold text-xl mb-2 px-5 py-5">The Coldest Sunset</div>
</a>
</div>
<div className="col-span-1 ml-2 mt-4 filter_custom h-[20rem] flex justify-start items-end rounded overflow-hidden shadow-lg" style={{backgroundImage: "url('https://source.unsplash.com/random/?UI/')",backgroundSize:'cover'}}>
<a href="#">
<div className="font-bold text-xl mb-2 px-5 py-5">The Coldest Sunset</div>
</a>
</div>
<div className="col-span-1 ml-2 mt-4 filter_custom h-[20rem] flex justify-start items-end rounded overflow-hidden shadow-lg" style={{backgroundImage: "url('https://source.unsplash.com/random/?Website/')",backgroundSize:'cover'}}>
<a href="#">
<div className="font-bold text-xl mb-2 px-5 py-5">The Coldest Sunset</div>
</a>
</div>





</div>


</div>


   </section>
  )
}
