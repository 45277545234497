import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/web-dev.svg';
import { isMobile } from 'react-device-detect';

const Hero = (props) => {
    return (
        <>
            <div className="hero bg_custom_bg" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' style={{minHeight:props?.page !== undefined ? '30vh' : (isMobile ? 'auto' : '100vh'),justifyContent:props?.page !== undefined ? 'center' : 'between'}} className="flex flex-col mt-10 lg:flex-row py-8 text-center lg:text-left">
                      {
                        props?.page === undefined &&   <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                        <h1 className="mb-5 md:text-5xl text-3xl font-[300] text-gray-200 hover:text-white capitalize tracking-wider" style={{lineHeight:"60px"}}>
                       empower <strong className='text-white'>pioneering startups</strong> and <strong className='text-white'>larges Companies</strong>, Offering <strong>Cutting-Edge</strong> Software Solutions
                        </h1>
                        <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                Learn more
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                          
                        </div>
                    </div>
                      }
                      {
                        props?.page !== undefined && <div>

<h1 className="md:text-5xl text-3xl font-[400] text-gray-200 hover:text-white capitalize tracking-wider text-center" style={{lineHeight:"60px"}}>
{props?.page}
                        </h1>
                        </div>
                      }
                      
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;