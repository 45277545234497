import React from 'react';


const WhyChoseUs = () => {



    return (
     
  <section
    id="features"
    data-aos="zoom-in-down"
    className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-gray-200 bg-white"
  >
    <div className="relative mx-auto max-w-5xl text-center">
   
      <h2 className="block w-full text-blue-800 font-bold text-3xl sm:text-4xl">
      Why Opt for Us?

      </h2>
      <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-500">
      Amidst a saturated market offering similar services, what sets Refonte apart as the 
ideal choice for your project?
      </p>
    </div>
    <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
        <img src="/icons/cost.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
        <h3 className="mt-6 text-black">Cost-Effective Solutions</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        Our products and services are priced affordably, ensuring that clients receive top-
notch quality without breaking the bank.
        </p>
      </div>
 
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
      <img src="/icons/expert.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
     
        <h3 className="mt-6 text-black">Expert Team</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        We're a team of proficient, certified, and seasoned individuals equipped with the 
professional prowess to meet all our clients' needs.
        </p>
      </div>
 
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
      <img src="/icons/electricity.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
     
        <h3 className="mt-6 text-black">Stay Ahead with Cutting-Edge Technology</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        At Refonte, we're committed to staying current with the latest advancements in 
technology. We continuously update and upgrade our tools and techniques to 
deliver top-tier products and services to our clients.
        </p>
      </div>
 
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
      <img src="/icons/transparency.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
     
        <h3 className="mt-6 text-black">Unwavering Transparency</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        What distinguishes us from competitors is our unwavering commitment to 
transparency. We firmly believe in complete honesty and loyalty to our clients.
        </p>
      </div>
 
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
      <img src="/icons/support.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
     
        <h3 className="mt-6 text-black">Round-the-Clock Support</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        At Refonte, we're dedicated to our clients' needs. Our support team is available 
24/7, ensuring assistance is always at hand whenever you need it.
        </p>
      </div>
 
      <div className="rounded-md border border-gray-300 shadow-xl bg-white p-8 text-center shadow">
      <img src="/icons/client.svg" className='w-[30px] ml-auto mr-auto mb-3 mt-3'/>
     
        <h3 className="mt-6 text-black">Client-Centric Approach</h3>
        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-500">
        At Refonte, we prioritize the satisfaction of our clients above all else. Our primary
goal is to fulfill and enhance our clients' needs
        </p>
      </div>
 

    </div>

  </section>
          
    )
}

export default WhyChoseUs;