import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="max-w-full mx-auto px-4 sm:px-6 bg-white border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 3rd block */}
            <div className="col-span-6 lg:col-span-3 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">
                OUR SERVICES
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Web Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Mobile App Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Digital Marketing
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    UI/UX Designing
                  </Link>
                </li>
              </ul>
            </div>

            {/* 2nd block */}
            <div className="col-span-12 lg:col-span-2 ml-7 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/about"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/services"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/contact"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 lg:col-span-4 ml-7 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">Reach</h6>
              <div className="flex flex-col">
                <a className="text-[#013289] text-lg" href="tel:+447488845674">
                  +447488845674
                </a>
                <a
                  className="text-[#013289] text-md mt-1 mb-1"
                  href="mailto:contact@refontetech.com"
                >
                  contact@refontetech.com
                </a>
                <a
                  className="text-[#013289] text-md"
                  target="_blank"
                  href="https://www.google.com/maps/place/1+Poulton+Cl,+Dover+CT17+0HL,+Royaume-Uni/@51.1300441,1.2860428,17z/data=!3m1!4b1!4m6!3m5!1s0x47debb1df3c62a23:0xd77d1f7ba886d82b!8m2!3d51.1300441!4d1.2860428!16s%2Fg%2F11c5fpwmgv?entry=ttu"
                  rel="noreferrer"
                >
                  United Kingdom, London 1 poulton close, dover kent CT17 0HL.
                </a>
              </div>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
              <div className="text-xl mb-6 text-[#013289]">
                Social Media Links.
              </div>

              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0 flex-wrap">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/refonte-learning/"
                      className="flex justify-center items-center "
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/linkedin.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="linkedin"
                      />
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCqXtyHSlxYo_r1xrxIGPHpg"
                      className="flex justify-center items-center "
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/youtube.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="youtube"
                      />
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61558190615192&is_tour_dismissed"
                      className="flex justify-center items-center "
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/facebook.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/refonte_learning"
                      className="flex justify-center items-center "
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/instagram.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      target="_blank"
                      href="https://twitter.com/RefonteLearning"
                      className="flex justify-center items-center "
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/x.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="twitter"
                      />
                    </a>
                  </li>
                  <li className="ml-4">
                    <a
                      target="_blank"
                      href="https://www.tiktok.com/@refonte.learning"
                      className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="Twitter"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/tiktok.png"
                        className="min-w-[30px] max-w-[30px] h-[30px] object-cover rounded-md"
                        alt="tiktok"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
