import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const Services = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };


    return (
        <div id="services" className="bg-gray-100 py-12 min-h-[100vh]" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4 flex flex-col justify-center items-center">
                        <h2 className="my-2 text-center text-black text-3xl text-blue-900 uppercase font-bold">Services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-black lg:max-w-[30%] text-sm lg:text-sm font-normal text-gray-600">
                            
                        Enhancing the E-Commerce Experience with Refonte: Putting a Human Touch to 
Online Shopping

                        </h2>
                    </div>

                    <div className="lg:px-12 px-6" data-aos="fade-down" data-aos-delay="600">
                        <Carousel removeArrowOnDeviceType={['mobile','tablet']} responsive={responsive} showDots={false} autoPlay={true} autoPlaySpeed={1500} infinite={true}>
                            
                        <div className="bg-white lg:min-h-[80vh] lg:max-h-[80vh] min-h-[60vh] max-h-[60vh] overflow-hidden text-gray-700  hover:text-white mr-4 rounded-lg  p-3 group flex flex-col align-center justify-between">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t transition duration-1000 lg:max-h-[40vh] lg:min-h-[40vh] min-h-[20vh] max-h-[20vh] w-[100%] ease-in-out" src={"https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-black">Mobile App Development</h2>
                                    <p className="text-md font-medium max-h-[15vh] overflow-auto text-gray-500">
                                    At Refonte, we excel in crafting cutting-edge and distinctive mobile applications 
tailored to fulfill contemporary business requirements. We specialize in 
developing diverse apps across various industry sectors.
                                    </p>
                                
                                </div>
                <center>
                <button onClick={()=>window.location.href = '/contact'} class="w-[95%] mt-2 transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Learn More
</button>
                </center>
                            </div>


                            <div className="bg-white lg:min-h-[80vh] lg:max-h-[80vh] min-h-[60vh] max-h-[60vh] overflow-hidden text-gray-700  hover:text-white mr-4 rounded-lg  p-3 group flex flex-col align-center justify-between">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t transition duration-1000 lg:max-h-[40vh] lg:min-h-[40vh] min-h-[20vh] max-h-[20vh] w-[100%] ease-in-out" src={"https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-black">Web Development Services</h2>
                                    <p className="text-md font-medium max-h-[15vh] overflow-auto text-gray-500">
                                    At Refonte, we are a team of dedicated IT experts committed to delivering top-
tier, budget-friendly web development solutions to our clients. If you're seeking 
premium outsourced web development services, refonte stands out as a prime 
option. Our paramount objective is to help our clients achieve their company 
goals.
                                    </p>
                                
                                </div>
                <center>
                <button onClick={()=>window.location.href = '/contact'} class="w-[95%] mt-2 transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Learn More
</button>
                </center>
                            </div>

                            <div className="bg-white lg:min-h-[80vh] lg:max-h-[80vh] min-h-[60vh] max-h-[60vh] overflow-hidden text-gray-700  hover:text-white mr-4 rounded-lg  p-3 group flex flex-col align-center justify-between">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t transition duration-1000 lg:max-h-[40vh] lg:min-h-[40vh] min-h-[20vh] max-h-[20vh] w-[100%] ease-in-out" src={"https://images.unsplash.com/photo-1576153192396-180ecef2a715?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-black">UI/UX Design</h2>
                                    <p className="text-md font-medium max-h-[15vh] overflow-auto text-gray-500">
                                    At Refonte, we understand the impact of well-crafted user interfaces, which have 
the power to leave a lasting impression. Innovative content not only captivates 
users but also motivates them to engage. Our expertise lies in delivering top-
notch, strategic UI/UX design solutions. With a team comprising some of the most
imaginative and skilled professionals in the field, we ensure exceptional quality 
and creativity.
                                    </p>
                                
                                </div>
                <center>
                <button onClick={()=>window.location.href = '/contact'} class="w-[95%] mt-2 transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Learn More
</button>
                </center>
                            </div>

                            <div className="bg-white lg:min-h-[80vh] lg:max-h-[80vh] min-h-[60vh] max-h-[60vh] overflow-hidden text-gray-700  hover:text-white mr-4 rounded-lg  p-3 group flex flex-col align-center justify-between">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t transition duration-1000 lg:max-h-[40vh] lg:min-h-[40vh] min-h-[20vh] max-h-[20vh] w-[100%] ease-in-out" src={"https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-black">Digital Marketing Services</h2>
                                    <p className="text-md font-medium max-h-[15vh] overflow-auto text-gray-500">
                                    Through Refonte's adept digital marketing services, clients typically experience a
substantial 40% boost in their returns from advertising campaigns. Our expertise 
has significantly contributed to enhancing revenue streams. Our marketing team 
comprises seasoned professionals who are proficient and experienced in their 
field. We offer a comprehensive suite of services including SEO, advertising, 
content creation, social media management, and more
                                    </p>
                                
                                </div>
                <center>
                <button onClick={()=>window.location.href = '/contact'} class="w-[95%] mt-2 transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Learn More
</button>
                </center>
                            </div>
                            
                                          
                        </Carousel>
                    </div>
            </section>

          
        </div>
    )
}

export default Services;