import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import WhyChoseUs from '../components/WhyChoseUs';
import NavBar from '../components/Navbar/NavBar';
import { isMobile } from 'react-device-detect';


const Careers = () => {
    return (
        <>
            <Hero page="Careers"/>
            <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">

            <div className='grid mb-10 transition-all lg:grid-cols-[1fr_0.5fr] sm:grid-cols-1 align-center items-center'>
                {
                  isMobile &&   <div className="px-12 mb-8" data-aos="fade-up" data-aos-delay="700">
                  <div style={{borderTopLeftRadius:isMobile ? 0 : 40,borderBottomLeftRadius:isMobile ? 0 : 40,overflow:'hidden'}}>
                  <img style={{objectFit:'cover',flex:1}} src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></img>
                  
                  </div>
                                      </div>
                }
                  <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <h1 className='mb-5 hover:text-yellow-600 text-black font-[600] uppercase text-3xl'>Discover possibilities</h1>
                      <p>
   
                      What's the next destination on your journey? Are advancements in technology 
ahead? Are you striving to enhance yourself? Or maybe foster a more robust creator 
community? We'll equip you with the tools, methodologies, and individuals to help 
you reach your desired destination. So you're motivated to innovate what comes 
next, progress in your career, and extend your capabilities with your collaborator. 
Move forward thoughtfully. Bring along the entire world with you.
                      </p><br/>
                      <button onClick={()=>window.location.href = '/contact'} class="hover:w-[30%] w-[100%] lg:w-[20%] hover:scale-[1.1] transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Apply Now
</button>
                    
                    </div>
                  {
                    !isMobile &&   <div className="px-12" data-aos="fade-up" data-aos-delay="700">
                    <div style={{borderTopLeftRadius:isMobile ? 0 : 40,borderBottomLeftRadius:isMobile ? 0 : 40,overflow:'hidden'}}>
                    <img style={{objectFit:'cover',flex:1}} src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></img>
                    
                    </div>
                                        </div>
                  }
                  </div>

                  <div className='grid mt-10 transition-all lg:grid-cols-[0.5fr_1fr] sm:grid-cols-1 align-center items-center'>
                  <div className="px-12" data-aos="fade-up" data-aos-delay="700">
<div style={{borderTopRightRadius:isMobile ? 0 :40,borderBottomRightRadius:isMobile ? 0 :40,overflow:'hidden',marginBottom:isMobile ? 8 : 0}}>
<img style={{objectFit:'cover',flex:1}} src="https://images.unsplash.com/photo-1483058712412-4245e9b90334?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></img>

</div>
                    </div>
                  <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <h1 className='mb-5 hover:text-yellow-600 text-black font-[600] uppercase text-3xl'>Our traditions are deeply ingrained</h1>
                      <p>
   
                      Nurturing creativity and ingenuity We cultivate a adaptable work setting centered on 
achieving optimal outcomes. While our headquarters boasts stunning facilities 
located in the vicinity of Indore, India, we promote a balance between virtual and 
face-to-face engagement.
                      </p><br/>
                      <button onClick={()=>window.location.href = '/contact'} class="hover:w-[30%] w-[100%] lg:w-[20%] hover:scale-[1.1] transition-all duration-1000 bg-transparent hover:bg-yellow-500 text-black font-semibold hover:text-black py-2 px-4 border border-black hover:border-transparent rounded">
  Apply Now
</button>
                    
                    </div>
                  
                  </div>

<br/>
<br/>

<br/>

<div className='max-w-[95%] ml-auto mr-auto grid lg:grid-cols-2 grid-cols-1 gap-x-5'>

<a href="https://refontelearning.com/study-and-internship" target="_blank">
<div className='hover:scale-[1.02] bg-[#4488BF] h-[350px] px-10 py-10 col-span-1 rounded-md grid lg:grid-cols-2 grid-cols-1 lg:justify-between justify-center items-center'>
<h1 className='col-span-1 text-3xl text-white font-[800]'>Refonte Global Training & Internship Program</h1>
<img src="/internee.svg" className='mt-5 lg:mt-0 w-[350px] col-span-1'/>

</div>
</a>

<a href="/" target="_blank">
<div className='bg-[#DABF40] lg:mt-0 mt-5 hover:scale-[1.02] h-[350px] px-10 py-10 col-span-1 rounded-md grid lg:grid-cols-2 grid-cols-1 lg:justify-between justify-center items-center'>
<h1 className='col-span-1 text-3xl text-black font-[800]'>Explore Opportunities By Location</h1>
<img src="/career.svg" className='mt-5 lg:mt-0 w-[350px] col-span-1'/>

</div>
</a>

</div>

            </section>


       <center>
   
    
       </center>
          
        </div>
            <Cta/>
            <Footer />
        </>

    )
}

export default Careers;

