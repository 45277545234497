import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';

const NavLinks = () => {
    return (
        <>
         <NavLink to="/" className={({ isActive }) => isActive ? "border_down" : ""}>
                <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/">
                    Home
                </HashLink>
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "border_down" : ""}>
                <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/about">
                    About
                </HashLink>
            </NavLink>
            <NavLink to="/services" className={({ isActive }) => isActive ? "border_down" : ""}>
                <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/services">
                    Services
                </HashLink>
            </NavLink>
            <a href="http://refontelearning.com/" className={({ isActive }) => isActive ? "border_down" : ""}>
                <a className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth>
                    Learning
                </a>
            </a>
            <NavLink to="/careers" className={({ isActive }) => isActive ? "border_down" : ""}>
                <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/careers">
                    Careers
                </HashLink>
            </NavLink>
            <NavLink to="/contact" className={({ isActive }) => isActive ? "border_down" : ""}>
                <HashLink className="text-white contact_pg bg_cust inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/contact">
                    Contact Us
                </HashLink>
            </NavLink>
        </>
    )
}

export default NavLinks;
